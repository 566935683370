import { Component, Inject } from '@angular/core';

@Component({
    selector: 'clui-archive-confirm-dialog',
    templateUrl: './archive-delete-confirm.component.html',
})

export class ArchiveDeleteConfirmDlgComponent {

    constructor() { }
}
