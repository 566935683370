import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'clui-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.css'],
})
export class LoginPageComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
